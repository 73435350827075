import request from '@/utils/axios'

// 获取产品信息
export function getProductList(data) {
  return request({
    url: '/v1/products/list',
    method: 'POST',
    data
  })
}

// 创建订单
export function createOrder(data) {
  return request({
    url: '/v1/pay/create',
    method: 'POST',
    data
  })
}

// 支付
export function payOrder(data) {
  return request({
    url: '/v1/pay',
    method: 'POST',
    data
  })
}

// 检查订单
export function checkOrder(data) {
  return request({
    url: `/v1/pay/info/${data.id}`,
    method: 'GET',
    data
  })
}