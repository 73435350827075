var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"richTextContainer",staticClass:"price-container",attrs:{"id":"price"}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.content.title))]),_c('p',{staticClass:"des"},[_vm._v(_vm._s(_vm.content.des))]),(_vm.content.moreLocal && _vm.lang !== 'en')?_c('div',{staticClass:"select-local"},[_c('div',{staticClass:"local-title"},[_vm._v("Please select the exchange rate")]),_c('el-select',{staticStyle:{"width":"360px"},attrs:{"placeholder":"Please select language"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}},_vm._l((_vm.contryList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.key,"value":item.value}})}),1)],1):_vm._e(),_c('div',{staticClass:"price-list",class:[`price-list-${_vm.productList.length}`]},_vm._l((_vm.content.priceSetup),function(item,idx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.productList[idx]),expression:"productList[idx]"}],key:idx,staticClass:"price-setup"},[_c('div',{staticStyle:{"display":"flex","align-items":"center","flex-wrap":"wrap"}},[(_vm.productList[idx])?_c('div',{domProps:{"innerHTML":_vm._s(
            item.price
              .replace(/{money}/g, _vm.moneyShow(_vm.productList[idx]))
              .replace(/{currencyUnit}/g, _vm.productList[idx].currencyUnit||'$')
              .replace(/{useTime}/g, _vm.productList[idx].useTime)
          )}}):_vm._e(),_c('div',{staticClass:"tag"},[_vm._v(_vm._s(item.tag))])]),_c('ul',_vm._l((item.desList),function(line){return _c('li',{key:line,staticClass:"des"},[_c('span',{domProps:{"innerHTML":_vm._s(
              line.replace(
                /{quota}/g,
                (_vm.productList[idx] &&
                  _vm.productList[idx] &&
                  _vm.productList[idx].config &&
                  _vm.productList[idx].config.quota||'1800')
              )
              .replace(/{useTime}/g, _vm.productList[idx] && _vm.productList[idx].useTime || 'month')
            )}})])}),0),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.productList.length),expression:"productList.length"}],staticClass:"pay-button",style:(`cursor: ${_vm.loading ? 'wait' : ''}`),on:{"click":function($event){return _vm.handlePay(idx)}}},[_vm._v(" "+_vm._s(item.buttonTxt)+" ")]),(_vm.productList[idx])?_c('p',{staticClass:"tip",domProps:{"innerHTML":_vm._s(
          item.tip
            .replace(/{money}/g, _vm.moneyShow(_vm.productList[idx]))
            .replace(/{price}/g, _vm.moneyShow(_vm.productList[idx], 'price'))
            .replace(/{useTime}/g, _vm.productList[idx].useTime)
            .replace(/{currencyUnit}/g, _vm.productList[idx].currencyUnit||'$')
        )}}):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }