<template>
  <!-- 首页banner 组件 -->
  <div class="banner-container">
    <div class="banner-box">
      <div class="banner-left">
        <h1 class="title">{{ content.title }}</h1>
        <p class="des">{{ content.des }}</p>
      </div>
      <div class="banner-right">
        <div class="banner-bg"></div>
        <img :src="$getFile(content.img)" v-if="content.img" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerPhone",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
    source: {
      type: String,
      default: "",
    },
  },
  data() {
    let that = this;
    return {
      activeIdx: 0,
      swiperOptions: {
        initialSlide: 0,
        loop: false,
        effect: "fade",
        speed: 200,
        autoplay: {
          disableOnInteraction: false,
        },
        on: {
          transitionStart() {
            that.activeIdx = this.realIndex;
          },
        },
      },
    };
  },
  computed: {
    topEleBg() {
      return this.$store.state.topEleBg;
    },
  },
  methods: {
    swiperSwitch(idx) {
      this.$refs.swiper.swiper.slideTo(idx + 1);
    },

    SetPoint() {
      this.$api.saveOperationLog("Download_win_btncli", {
        source: this.source || "home",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .banner-container {
    overflow: hidden;

    .banner-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
      // flex-direction: column-reverse;
      width: 100%;
      margin: 32px auto 0;
      margin-top: 0;
      position: relative;
      padding: 0px;
      height: 600px;
      background: #202124;
      // border-radius: 16px;

      .banner-left {
        width: 627px;
        height: auto;
        cursor: default;
        position: absolute;
        left: 10%;

        .title {
          margin: -10px 0 4px;
          font-size: 46px;
          font-family: Poppins;
          color: #fff;
          line-height: 52px;
          position: relative;
          z-index: 100;
        }

        .des {
          max-width: 626px;
          font-size: 18px;
          font-weight: bold;
          font-family: Poppins-Bold, Poppins;
          color: rgba(255, 255, 255, 0.7);
          line-height: 28px;
          margin-top: 20px;
          position: relative;
          z-index: 100;
          .des1 {
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: 400;
            font-family: Poppins-Regular, Poppins;
            color: var(--theme-primary-black);
            line-height: 24px;
          }
        }
      }

      .banner-right {
        height: 600px;
        width: 1067px;
        position: absolute;
        right: 72px;
        .banner-bg {
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          position: absolute;
          z-index: 10;
          background: linear-gradient(
              to right,
              rgb(32, 33, 36) 0,
              rgba(0, 0, 0, 0) 56%
            ),
            linear-gradient(to top, rgb(32, 33, 36) 0, rgba(0, 0, 0, 0) 56%),
            linear-gradient(to left, rgb(32, 33, 36) 0, rgba(0, 0, 0, 0) 56%);
        }
        img {
          z-index: 8;
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          right: 0;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .banner-container {
    overflow: hidden;

    .banner-box {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: row-reverse;
      // flex-direction: column-reverse;
      width: 100%;
      margin: 0.32rem auto 0;
      margin-top: 0;
      position: relative;
      padding: 0px;
      height: 6rem;
      background: #202124;
      // border-radius: 0.16rem;

      .banner-left {
        width: 100%;
        height: auto;
        cursor: default;
        position: relative;
        z-index: 1000;
        padding: 0 0.2rem;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        left: 0%;
        padding-bottom: 0.5rem;
        .title {
          font-size: 0.46rem;
          font-family: Poppins;
          color: #fff;
        }

        .des {
          font-size: 0.18rem;
          font-weight: bold;
          font-family: Poppins-Bold, Poppins;
          color: rgba(255, 255, 255, 0.7);
          margin-top: 0.2rem;
          position: relative;
          z-index: 100;
          .des1 {
            margin-bottom: 0.2rem;
            font-size: 0.14rem;
            font-weight: 400;
            font-family: Poppins-Regular, Poppins;
            color: var(--theme-primary-black);
          }
        }
      }

      .banner-right {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        .banner-bg {
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          position: absolute;
          z-index: 10;
          background: linear-gradient(
              to right,
              rgb(32, 33, 36) 0,
              rgba(0, 0, 0, 0) 56%
            ),
            linear-gradient(to top, rgb(32, 33, 36) 0, rgba(0, 0, 0, 0) 56%),
            linear-gradient(to left, rgb(32, 33, 36) 0, rgba(0, 0, 0, 0) 56%);
        }
        img {
          z-index: 8;
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          right: 0;
        }
      }
    }
  }
}
</style>