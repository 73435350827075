<template>
  <div class="upload-wrap">

    <!-- <div class="left-wrap">
      <ul class="select-wrap" :class="i == selectId ? 'type-active':''" v-for="(itm,i) in 8" :key="i" @click="selectType(i)">
        <li class="type-item">类型{{i}}</li>
      </ul>
    </div> -->

    <div class="picture-dialog-warp">
      <div class="select-file" v-show="!showAnimation" @click="uploadBtn(false)">
        <input id="filed" ref="file" type="file" accept="image/*" hidden="" @change="filePreview" />
        <div class="select-btn">Click to select a file</div>
      </div>

      <div class="picture-dialog-box" :class="[{ showAnimation: showAnimation }, { hideAnimate: !showAnimation }]"
        v-show="showAnimation">
        <div class="title">{{ title }}</div>
        <div class="img-warp">
          <div class="image-warp">
            <img class="path-img" v-if="originalPath" :src="originalPath" alt="" />
            <div class="loading-img" v-if="!originalPath"></div>
            <img :src="$getFile('ui/loading.png')" v-if="!originalPath" class="loading" alt="" />
          </div>
          <img class="icon_conversion" :src="$getFile('ui/icon-conversion.png')" alt="" />
          <div class="image-warp">
            <img class="path-img" :src="conversionPath" v-if="originalPath" alt="" />
            <div class="loading-img" v-if="!originalPath && !finished"></div>

            <img :src="$getFile('ui/loading.png')" v-if="!loading && !finished" class="loading" alt="" />
            <div class="progress-fixed" v-if="loading && !finished">
              <el-progress type="circle" class="progress-warp" :width="32" :show-text="false" color="#fff"
                :percentage="progress" />
            </div>
          </div>
        </div>
        <div class="btn-wrap">
          <div class="replace" @click="uploadBtn(true)">replace</div>
          <AButton custom-class="picture-btn" :disabled="true" type="primary" :loading="payLoading" text="Photo Repair"
            @click="submitForm" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUploadPic } from '@/common/uploading'
import { mapState } from 'vuex'
import utils from '@/common/utils'

export default {
  name: 'messageDialog',
  props: {
    path: {
      type: String,
      default: '',
    },
    expirationTime: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  data() {
    return {
      selectId: '',
      seleect: false,
      footerPath: 'pay',
      orderInfo: {},
      paySuccess: false,
      initTime: 70,
      finished: false,
      payLoading: true,
      title: 'Uploading photo...',
      loading: false,
      showAnimation: false,
      dialogVisible: false,
      conversionPath: '',
      originalPath: '',
      templatePath: '',
      progress: 0,
      timer: null,
      titleTimer: null,
      titleList: ['Photo Repair...', 'Colorizing image...', 'Balancing color effects...', "Restoring scratches..."],

      test: {}
    }
  },
  mounted() {
  },
  methods: {
    // 点击上传图片 1
    uploadBtn(type) {
      if (!type) {
        document.getElementById('filed').click()
        return
      }
      // 中断请求
      source.cancel('Operation canceled by the user.');
      this.showAnimation = false
      this.originalPath = ''
      this.conversionPath = ''
      this.progress = 0
      document.getElementById('filed').value = ''
      document.getElementById('filed').click()
    },
    // 制作图片 、 创建订单 2
    async filePreview(e) {
      if (!this.expirationTime) {
        this.$message({
          showClose: true,
          message: 'You are not a member yet and cannot use this feature temporarily',
          type: 'error'
        });
        return
      }
      try {
        this.select = true
        var files = e.target.files[0]
        let types = ['image/jpeg', 'image/jpg', 'image/png']
        const isImage = types.includes(files.type)
        if (!isImage) {
          this.$message.error('This format is currently not supported')
          return
        }
        setTimeout(() => {
          this.showAnimation = true
        }, 100)
        this.finished = false
        this.payLoading = true
        // localStorage.setItem('orderInfo', '')
        // const order = await this.$api.createOrder({ type: 7 })
        // localStorage.setItem('orderInfo', JSON.stringify(order))
        // this.orderInfo = order
        const file = await getUploadPic(files)
        e.target.value = ''
        this.originalPath = file
        this.conversionPath = file
        this.loading = true
        if (!this.userInfo?.subStatus || this.userInfo?.subStatus !== 1) {
          this.payLoading = false
        }
        this.changeTitle(0)
        this.setProgress()
        try {
        let opening = await this.$api.getPhotoMattingInfo({ imgInPath: file, action: 'colorization' })
        if(Number(opening.success)!==1){
          this.$message.error(opening.msg)
          this.uploadBtn(true)
          return
        }
        let result = await this.$api.getPhotoMattingInfo({ imgInPath: opening.obj, action: 'superResolution' })
        if (result) {
          this.conversionPath = result.obj
          setTimeout(() => {
            this.finished = true
          }, 800)
        }
          
        } catch (error) {
          console.log(error)
        }
        // this.$api.orderProcess({
        //   orderId: order.id,
        //   pictureUrl: file,
        //   uid: order.uid,
        // }).then(ret => {
        //   setTimeout(() => {
        //     this.getOrderStatus()
        //   }, 1000)
        // })
      } catch (error) {
        console.log(error)
      }
    },
    // 查看订单状态
    getOrderStatus() {
      if (!this.dialogVisible) {
        return
      }
      this.$api.getByUid({ uid: this.orderInfo.uid }).then(async result => {
        if (result.serviceStatus > 0) {
          //制作完成
          if (result.serviceStatus === 3) {
            const result = JSON.parse(result.extendJson)
            this.conversionPath = result.noWaterUrl
          }
          setTimeout(() => {
            this.finished = true
          }, 500)
          this.orderInfo = result
          if (result.serviceStatus === 1) {
            window.location.href = `${this.footerPath['result']
              }${utils.obj2StrParams({
                uid: this.orderInfo.uid,
                payType: 0,
              })}`
            return
          }
          this.payLoading = false
          return
        }
        setTimeout(() => {
          this.getOrderStatus()
        }, 1000)
      })
    },
    // 提交
    submitForm() {
      if (this.conversionPath) {
        window.open(this.conversionPath)
      }
      // if (this.userInfo?.subStatus === 1) {
      //   this.vipPay().then(type => {
      //     if (!type) {
      //       return false
      //     }
      //     if (this.orderInfo.serviceStatus > 1) {
      //       this.$point('mb_repair2')
      //       window.location.href = `${this.footerPath['result']}
      //         ${utils.obj2StrParams({
      //           uid: this.orderInfo.uid,
      //           payType: this.orderInfo.serviceStatus,
      //         })}`
      //     }
      //   })
      //   return
      // }
      // this.$point('mb_repair2')
      // window.location.href = this.footerPath['pay']
      // this.$router.push({
      //   path: this.footerPath['pay'],
      // })
    },
    // 判断
    async vipPay() {
      return new Promise(async (resolve, reject) => {
        if (this.paySuccess) {
          resolve(true)
        } else {
          try {
            await this.$api.payCredits(this.orderInfo)
            this.paySuccess = true
            resolve(true)
          } catch (error) {
            reject(false)
            console.log(error, '>>>>>')
          }
        }
      })
    },

    changeTitle(num) {
      if (this.finished) {
        this.title = "Photo has been restored!"
        return
      }
      const list = this.titleList
      this.title = list[num]
      this.titleTimer = setTimeout(() => {
        num = num + 1
        if (num > list.length - 1) {
          num = 0
        }
        this.changeTitle(num)
      }, 2000)
    },
    //获取进度
    setProgress() {
      let minSlow = 60
      let maxSlow = 90
      this.timer = setTimeout(() => {
        this.progress = this.progress < 90 ? this.progress + 1 : 90
        if (this.progress > minSlow && this.progress < maxSlow) {
          if (!this.finished) {
            this.initTime += 10
          } else {
            //此处可重置上传进度增长周期
          }
        }
        if (this.finished) {
          this.progress = 100
          //处理完成
          clearTimeout(this.timer)
          clearTimeout(this.titleTimer)
          this.changeTitle(0)
          this.timer = null //清除定时器，避免导致内存溢出
          return
        }
        this.setProgress()
      }, this.initTime)
    },
    hide(type) {
      _this.showAnimation = false
      setTimeout(() => {
        this.dialogVisible = false
        this.$emit('hideDialog')
      }, 1000)
      this.$emit('close', type)
    },

    // 选择类型
    selectType(type) {
      this.selectId = type
    }
  },
}
</script>

<style lang="scss" scoped>
@keyframes wrapper-gradient {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 980px) {
  .upload-wrap {
    display: flex;
    align-items: center;

    .left-wrap {
      display: grid;
      grid-template-rows: repeat(4, 1fr);
      /* 4行，每行大小相等 */
      grid-template-columns: repeat(2, 1fr);
      /* 2列，每列大小相等 */
      margin-right: 10px;

      .select-wrap {
        position: relative;
        list-style: none;
        width: 120px;
        height: 120px;
        text-align: center;
        margin-bottom: 10px;
        margin-right: 10px;
        background: #dcdfe6;

        .type-item {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 30px;
          line-height: 30px;
          background: #ffffff80;
        }
      }

      .type-active {
        width: 118px;
        height: 118px;
        border: 1px solid #409eff;
      }
    }

    .picture-dialog-warp {
      width: 100%;
      height: 100%;
      text-align: center;

      .select-file {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 480px;
        background: #dcdfe6;
        border-radius: 5px;

        .select-btn {
          width: 420px;
          height: 240px;
          border: 3px dashed #000;
          border-radius: 15px;
          line-height: 240px;
          cursor: pointer;
          font-size: 22px;

          &:hover {
            color: #fff;
            border: 3px dashed #fff;
          }
        }
      }

      .picture-dialog-box {
        padding: 10px 0 30px;
        background: rgb(226, 218, 218);

        &.showAnimation {
          animation: wrapper-gradient 0.25s linear;
        }

        &.hideAnimate {
          animation: hide-gradient 0.25s linear;
          animation-fill-mode: forwards; //  动画停留在最后一刻，也就是隐藏
        }

        .title {
          font-size: 36px;
          font-weight: bold;
          font-family: Manrope-Bold, Manrope;
          color: #333333;
          min-height: 50px;
          line-height: 50px;
        }

        .img-warp {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 50px;

          .icon_conversion {
            width: 64px;
            margin: 0 12px;
          }

          .image-warp {
            width: 240px;
            display: flex;
            align-content: center;
            justify-content: center;
            height: 240px;
            border-radius: 8px;
            border: 1px solid rgba(222, 226, 230, 0.41);
            position: relative;

            .path-img {
              width: 100%;
              border-radius: 8px;
              object-fit: contain;
            }

            .loading {
              width: 56px;
              height: 56px;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
              z-index: 10;
              animation: rotate 2s linear infinite;
              -webkit-animation: rotate 2s linear infinite;
            }

            .progress-fixed {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 10;
              background: rgba($color: #000000, $alpha: 0.3);

              .progress-warp {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                z-index: 11;
                width: 56px;
                height: 56px;

                ::v-deep path:first-child {
                  stroke: rgba(0, 0, 0, 0.5);
                }
              }
            }

            .loading-img {
              position: absolute;
              left: 0;
              top: 0;
              background: #cccccc;
              width: 100%;
              height: 100%;
              border-radius: 8px;
              z-index: 1;
            }
          }
        }

        .btn-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 50px;

          .replace {
            width: 200px;
            height: 54px;
            line-height: 54px;
            border-radius: 27px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            margin-right: 20px;
            font-size: 20px;
            font-family: Manrope-Bold, Manrope;
            cursor: pointer;
          }

          .picture-btn {
            width: 200px;
          }
        }
      }
    }
  }
}

@media (max-width: 981px) {
  .upload-wrap {
    display: flex;
    flex-direction: column-reverse;

    .left-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .select-wrap {
        position: relative;
        list-style: none;
        width: 1.65rem;
        height: 1.65rem;
        margin-top: 0.1rem;
        text-align: center;
        background: #dcdfe6;

        .type-item {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          font-size: 0.24rem;
          background: #ffffff80;
        }
      }

      .type-active {
        width: 1.61rem;
        height: 1.61rem;
        border: 1px solid #409eff;
      }
    }

    .picture-dialog-warp {
      .select-file {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 4.8rem;
        background: #dcdfe6;
        border-radius: .2rem;

        .select-btn {
          width: 3.2rem;
          height: 2rem;
          border: 3px dashed #000;
          border-radius: 0.15rem;
          line-height: 2rem;
          font-size: 0.28rem;

          &:active {
            color: #fff;
            border: 3px dashed #fff;
          }
        }
      }

      .picture-dialog-box {
        width: 100%;
        background: #ffffff;
        text-align: center;

        &.showAnimation {
          animation: wrapper-gradient 0.25s linear;
        }

        &.hideAnimate {
          animation: hide-gradient 0.25s linear;
          animation-fill-mode: forwards; //  动画停留在最后一刻，也就是隐藏
        }

        .title {
          font-size: 0.36rem;
          font-weight: bold;
          font-family: Manrope-Bold, Manrope;
          color: #333333;
          min-height: 0.5rem;
          line-height: 0.5rem;
        }

        .img-warp {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.5rem;

          .icon_conversion {
            width: 0.64rem;
            margin: 0 0.12rem;
          }

          .image-warp {
            width: 2.4rem;
            display: flex;
            align-content: center;
            justify-content: center;
            height: 2.4rem;
            border-radius: 0.08rem;
            border: 1px solid rgba(222, 226, 230, 0.41);
            position: relative;

            .path-img {
              width: 100%;
              border-radius: 0.08rem;
              object-fit: contain;
            }

            .loading {
              width: 0.56rem;
              height: 0.56rem;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
              z-index: 10;
              animation: rotate 2s linear infinite;
              -webkit-animation: rotate 2s linear infinite;
            }

            .progress-fixed {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 10;
              background: rgba($color: #000000, $alpha: 0.3);

              .progress-warp {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                z-index: 11;
                width: 0.56rem;
                height: 0.56rem;

                ::v-deep path:first-child {
                  stroke: rgba(0, 0, 0, 0.5);
                }
              }
            }

            .loading-img {
              position: absolute;
              left: 0;
              top: 0;
              background: #f1e5fa;
              width: 100%;
              height: 100%;
              border-radius: 0.08rem;
              z-index: 1;
            }
          }
        }

        .btn-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.5rem;
          margin-bottom: 0.2rem;

          .replace {
            width: 2rem;
            height: 0.8rem;
            line-height: 0.8rem;
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            margin-right: 0.2rem;
            font-size: 0.3rem;
            font-family: Manrope-Bold, Manrope;
            border: 1px solid #eee;
          }

          .picture-btn {
            padding: 0 0.3rem;
          }
        }
      }
    }
  }
}</style>
